<!--
 * @Descripttion: code everything
 * @Author: Letmeouted
 * @Date: 2022-08-11 16:48:06
 * @LastEditors: 王华锋
 * @LastEditTime: 2023-06-12 10:27:34
-->
<template>
  <div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="姓名" prop="name" placeholder="请输入姓名">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="phone" placeholder="请输入手机号码">
        <el-input v-model="ruleForm.phone"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >查询</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" border style="width: 100%; height: 100%">
      <el-table-column prop="username" label="姓名" align="center">
      </el-table-column>
      <el-table-column prop="telephone" label="手机号码" align="center">
      </el-table-column>
      <el-table-column prop="uploadTime" label="上传时间" align="center">
      </el-table-column>
      <el-table-column prop="reportName" label="报告" align="center">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >{{scope.row.reportName}}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "search",
  data() {
    return {
      tableData: [],
      ruleForm: {
        name: "",
        phone: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
           if (valid) {
          // var arr = [];
          var arr1 = [];
          const loading = this.$loading({
            lock: true,
            text: "正在查询，请稍后！",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          console.log(this.ruleForm.name);
          // this.$http
          //   .post("/user", {
          //     name: this.ruleForm.name,
          //     phone: this.ruleForm.phone,
          //   })
          //   .then((res) => {
          //     this.tableData = ""
          //     if (res.data.title === "空") {
                // alert("抱歉！系统中暂无您的报告！")
                this.$http.post("/getYidaProcess", {
                  name: this.ruleForm.name,
                  phone: this.ruleForm.phone,
                }).then((res) => {
                  this.tableData = ""
                  setTimeout(() => {
                    loading.close();
                  });
                  console.log("res.data",res.data)
                  if (res.data.title === "空") {
                    alert("抱歉！系统中暂无您的报告！")
                  } else {
                    for (var i = 0; i < res.data.str2.length; i++) {
                      var time = new Date(res.data.str2[i].upload).getTime()-8*3600*1000
                      this.formatting(time)
                      arr1.push({
                        username:res.data.str2[i].username,
                        telephone:res.data.str2[i].telephone,
                        uploadTime:this.formatting(time),
                        fileURL:res.data.str2[i].address,
                        reportName:res.data.str2[i].reportName,
                        id:i+1
                      })
                      console.log(arr1)
                      this.tableData = arr1
                    }
                  }
                })
              // } else {
              //   console.log(res.data)
              //   for (var i = 0; i < res.data.str1.length; i++) {
              //     for(var k=0;k<res.data.str1[i].附件.length;k++){
              //       for (var j = 0; j < res.data.str2.length; j++) {
              //       if (
              //         res.data.str1[i].附件[k].file_token ==
              //         res.data.str2[j].file_token
              //       ) {
              //         arr.push({
              //           username: res.data.str1[i].姓名,
              //           telephone: res.data.str1[i].手机,
              //           uploadTime: new Date(
              //             res.data.str1[i].上传时间
              //           ).toLocaleString(),
              //           reportName: res.data.str1[i].报告名称,
              //           fileURL: res.data.str2[j].address,
              //           id:k+1
              //         });
              //         // console.log(arr)
              //         function unique(arr) {
              //           for (var m = 0; m < arr.length; m++) {
              //             for (var n= m + 1; n < arr.length; n++) {
              //               if (arr[m].fileURL == arr[n].fileURL) {
              //                 arr.splice(n, 1);
              //                 n--;
              //             }
              //           }
              //         }
              //         // console.log(arr)
              //          return arr;
              //         }
              //         unique(arr)
              //         console.log(arr)
              //         this.tableData = arr;
              //       }
              //     }
              //     }
                  
              //   }
              //   console.log("getYidaArr",arr)
              //   this.$http.post("/getYidaProcess", {
              //     name: this.ruleForm.name,
              //     phone: this.ruleForm.phone,
              //   }).then((res) => {
              //     // this.tableData = ""
              //     setTimeout(() => {
              //       loading.close();
              //     });
              //     console.log("res.data",res.data)
              //     if (res.data.title === "空") {
              //       // alert("抱歉！系统中暂无您的报告！")
              //     } else {
              //       for (var i = 0; i < res.data.str2.length; i++) {
              //         var time = new Date(res.data.str2[i].upload).getTime()-8*3600*1000
              //         this.formatting(time)
              //         arr.push({
              //           username:res.data.str2[i].username,
              //           telephone:res.data.str2[i].telephone,
              //           uploadTime:this.formatting(time),
              //           fileURL:res.data.str2[i].address,
              //           reportName:res.data.str2[i].reportName,
              //           id:i+1
              //         })
              //         console.log(arr)
              //         this.tableData = arr
              //       }
              //     }
              //   })
            //   }
            // });
          // alert("submit!");
        } else {
          alert("请确保提交数据的格式正确后再提交！");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.tableData = "";
    },
    handleClick(row) {
      console.log(row.fileURL);
      window.open(row.fileURL);
    },
    formatting(time){
         var time = new Date(time);
         var y = time.getFullYear();
         var m = time.getMonth()+1;
         var d = time.getDate();
         var h = time.getHours();
         var mm = time.getMinutes();
         var s = time.getSeconds();
         return y+'-'+this.repair0(m)+'-'+this.repair0(d)+' '+this.repair0(h)+':'+this.repair0(mm)+':'+this.repair0(s);
    },
    repair0(m){
	    return m<10?'0'+m:m 
	  }
  },
};
</script>
<style>
.demo-ruleForm {
  width: 95vw;
  display: grid;
  align-content: center;
  justify-content: center;
}
.el-loading-spinner {
  font-size: 80px;
  font-weight: bold;
}
.el-loading-mask .el-loading-spinner .el-loading-text {
  font-size: 18px;
  color:#fff;
}
</style>

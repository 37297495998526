/*
 * @Descripttion: code everything
 * @Author: Letmeouted
 * @Date: 2022-08-15 18:06:07
 * @LastEditors: Letmeouted
 * @LastEditTime: 2022-08-15 18:06:07
 */
import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App'
import axios from 'axios';//后台交互
Vue.prototype.$http=axios
//defaults 设置全局默认路径
axios.defaults.baseURL="/"
Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')

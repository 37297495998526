<!--
 * @Descripttion: code everything
 * @Author: Letmeouted
 * @Date: 2022-08-15 18:04:56
 * @LastEditors: Letmeouted
 * @LastEditTime: 2022-08-19 10:40:10
-->
<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/1625819935776861.png"> -->
    <HelloWorld />
     <search></search>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import search from './components/search.vue'
export default {
  name: 'App',
  components: {
    HelloWorld,search
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<!--
 * @Descripttion: code everything
 * @Author: Letmeouted
 * @Date: 2022-08-11 15:21:16
 * @LastEditors: 王华锋
 * @LastEditTime: 2023-04-28 08:58:25
-->
<template>
  <div>
    <h1>{{ msg }}</h1>
    <p>报告仅保留一个月</p>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      msg: "查询系统"
    };
  }
};
</script>
<style scoped>
h1{
  font-weight: normal;
}
p{
  font-size:14px;
}

</style>
